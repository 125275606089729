
// @ts-nocheck


export const localeCodes =  [
  "en",
  "de-DE",
  "es-ES",
  "it",
  "ja",
  "zh-CN",
  "pt-PT",
  "pt-BR",
  "ru-RU",
  "fr-FR",
  "uk-UA"
]

export const localeLoaders = {
  "en": [{ key: "../internationalization/en.json", load: () => import("../internationalization/en.json" /* webpackChunkName: "locale__vercel_path0_internationalization_en_json" */), cache: true }],
  "de-DE": [{ key: "../internationalization/de-DE.json", load: () => import("../internationalization/de-DE.json" /* webpackChunkName: "locale__vercel_path0_internationalization_de_DE_json" */), cache: true }],
  "es-ES": [{ key: "../internationalization/es-ES.json", load: () => import("../internationalization/es-ES.json" /* webpackChunkName: "locale__vercel_path0_internationalization_es_ES_json" */), cache: true }],
  "it": [{ key: "../internationalization/it.json", load: () => import("../internationalization/it.json" /* webpackChunkName: "locale__vercel_path0_internationalization_it_json" */), cache: true }],
  "ja": [{ key: "../internationalization/ja.json", load: () => import("../internationalization/ja.json" /* webpackChunkName: "locale__vercel_path0_internationalization_ja_json" */), cache: true }],
  "zh-CN": [{ key: "../internationalization/zh-CN.json", load: () => import("../internationalization/zh-CN.json" /* webpackChunkName: "locale__vercel_path0_internationalization_zh_CN_json" */), cache: true }],
  "pt-PT": [{ key: "../internationalization/pt-PT.json", load: () => import("../internationalization/pt-PT.json" /* webpackChunkName: "locale__vercel_path0_internationalization_pt_PT_json" */), cache: true }],
  "pt-BR": [{ key: "../internationalization/pt-BR.json", load: () => import("../internationalization/pt-BR.json" /* webpackChunkName: "locale__vercel_path0_internationalization_pt_BR_json" */), cache: true }],
  "ru-RU": [{ key: "../internationalization/ru-RU.json", load: () => import("../internationalization/ru-RU.json" /* webpackChunkName: "locale__vercel_path0_internationalization_ru_RU_json" */), cache: true }],
  "fr-FR": [{ key: "../internationalization/fr-FR.json", load: () => import("../internationalization/fr-FR.json" /* webpackChunkName: "locale__vercel_path0_internationalization_fr_FR_json" */), cache: true }],
  "uk-UA": [{ key: "../internationalization/uk-UA.json", load: () => import("../internationalization/uk-UA.json" /* webpackChunkName: "locale__vercel_path0_internationalization_uk_UA_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "files": [
        "internationalization/en.json"
      ]
    },
    {
      "code": "de-DE",
      "name": "Deutsch",
      "files": [
        "internationalization/de-DE.json"
      ]
    },
    {
      "code": "es-ES",
      "name": "Español",
      "files": [
        "internationalization/es-ES.json"
      ]
    },
    {
      "code": "it",
      "name": "Italiano",
      "files": [
        "internationalization/it.json"
      ]
    },
    {
      "code": "ja",
      "name": "日本語",
      "files": [
        "internationalization/ja.json"
      ]
    },
    {
      "code": "zh-CN",
      "name": "简体中文",
      "files": [
        "internationalization/zh-CN.json"
      ]
    },
    {
      "code": "pt-PT",
      "name": "Português",
      "files": [
        "internationalization/pt-PT.json"
      ]
    },
    {
      "code": "pt-BR",
      "name": "Português do Brasil",
      "files": [
        "internationalization/pt-BR.json"
      ]
    },
    {
      "code": "ru-RU",
      "name": "Русский",
      "files": [
        "internationalization/ru-RU.json"
      ]
    },
    {
      "code": "fr-FR",
      "name": "Français",
      "files": [
        "internationalization/fr-FR.json"
      ]
    },
    {
      "code": "uk-UA",
      "name": "Українська",
      "files": [
        "internationalization/uk-UA.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "internationalization",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "en",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "internationalization/en.json"
      }
    ]
  },
  {
    "code": "de-DE",
    "name": "Deutsch",
    "files": [
      {
        "path": "internationalization/de-DE.json"
      }
    ]
  },
  {
    "code": "es-ES",
    "name": "Español",
    "files": [
      {
        "path": "internationalization/es-ES.json"
      }
    ]
  },
  {
    "code": "it",
    "name": "Italiano",
    "files": [
      {
        "path": "internationalization/it.json"
      }
    ]
  },
  {
    "code": "ja",
    "name": "日本語",
    "files": [
      {
        "path": "internationalization/ja.json"
      }
    ]
  },
  {
    "code": "zh-CN",
    "name": "简体中文",
    "files": [
      {
        "path": "internationalization/zh-CN.json"
      }
    ]
  },
  {
    "code": "pt-PT",
    "name": "Português",
    "files": [
      {
        "path": "internationalization/pt-PT.json"
      }
    ]
  },
  {
    "code": "pt-BR",
    "name": "Português do Brasil",
    "files": [
      {
        "path": "internationalization/pt-BR.json"
      }
    ]
  },
  {
    "code": "ru-RU",
    "name": "Русский",
    "files": [
      {
        "path": "internationalization/ru-RU.json"
      }
    ]
  },
  {
    "code": "fr-FR",
    "name": "Français",
    "files": [
      {
        "path": "internationalization/fr-FR.json"
      }
    ]
  },
  {
    "code": "uk-UA",
    "name": "Українська",
    "files": [
      {
        "path": "internationalization/uk-UA.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
