import revive_payload_client_OjC8qBYzkh from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.4_@unocss+reset@0.59.4_encoding@0.1.13_es_rtauiaqrx34elxsarkewtznjx4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_BTnk71ySjs from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.4_@unocss+reset@0.59.4_encoding@0.1.13_es_rtauiaqrx34elxsarkewtznjx4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cNHr8uJ9gf from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.4_@unocss+reset@0.59.4_encoding@0.1.13_es_rtauiaqrx34elxsarkewtznjx4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_wrXrwjh5pe from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.4_@unocss+reset@0.59.4_encoding@0.1.13_es_rtauiaqrx34elxsarkewtznjx4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_Z5XsRzAmfx from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.4_@unocss+reset@0.59.4_encoding@0.1.13_es_rtauiaqrx34elxsarkewtznjx4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_WmpJCzfFql from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.4_@unocss+reset@0.59.4_encoding@0.1.13_es_rtauiaqrx34elxsarkewtznjx4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_Cs5K9DLo2m from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.30.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._i4tgbux4gc5noojwipxlqnqt5y/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import i18n_ha0tilKXTO from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.14.0_vue@3.4.21_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import view_transitions_client_ANyXHrDIha from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.4_@unocss+reset@0.59.4_encoding@0.1.13_es_rtauiaqrx34elxsarkewtznjx4/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_R7TWRGfqpQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.4_@unocss+reset@0.59.4_encoding@0.1.13_es_rtauiaqrx34elxsarkewtznjx4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import scroll_client_HJUo2UKx9n from "/vercel/path0/plugins/scroll.client.ts";
export default [
  revive_payload_client_OjC8qBYzkh,
  unhead_BTnk71ySjs,
  router_cNHr8uJ9gf,
  payload_client_wrXrwjh5pe,
  check_outdated_build_client_Z5XsRzAmfx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WmpJCzfFql,
  sentry_client_Cs5K9DLo2m,
  sentry_client_config_o34nk2sJbg,
  i18n_ha0tilKXTO,
  unocss_MzCDxu9LMj,
  view_transitions_client_ANyXHrDIha,
  chunk_reload_client_R7TWRGfqpQ,
  scroll_client_HJUo2UKx9n
]